import React, {useContext} from 'react';
import * as S from './MainSider/MainSider.styles';
import { RightOutlined } from '@ant-design/icons';
import { useResponsive } from 'hooks/useResponsive';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { Link } from 'react-router-dom';
import {useAuth} from "@app/hooks/useAuth";
import {
  LogoutOutlined
} from '@ant-design/icons';

interface SiderLogoProps {
  isSiderCollapsed: boolean;
  toggleSider: () => void;
}
export const SiderLogo: React.FC<SiderLogoProps> = ({ isSiderCollapsed, toggleSider }) => {
  const { tabletOnly } = useResponsive();
  const {setLogout} = useAuth();

  const user = useAppSelector((state) => state.user.user);

  const DoLogout = () => {
    setLogout();
  }

  return (
    <S.SiderLogoDiv>
      <S.SiderLogoLink to="/">
        <S.BrandSpan>{`${user?.name}`} {`${user?.team}`}</S.BrandSpan>
      </S.SiderLogoLink>
      <S.Text>
        <Link to="#" onClick={DoLogout}><LogoutOutlined style={{ fontSize: '35px' }} /></Link>
      </S.Text>
      {tabletOnly && (
        <S.CollapseButton
          shape="circle"
          size="small"
          $isCollapsed={isSiderCollapsed}
          icon={<RightOutlined rotate={isSiderCollapsed ? 0 : 180} />}
          onClick={toggleSider}
        />
      )}
    </S.SiderLogoDiv>
  );
};
