import React, { useContext, useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { httpApi } from "@app/api/http.api";
import { deleteToken, deleteUser, persistToken, readToken, persistUser } from '@app/services/localStorage.service';
import AuthContext from "@app/context/authContext";

export interface AuthSlice {
    token: string | null;
}

const initialState: AuthSlice = {
    token: readToken(),
};

export const useAuth = () => {
    const navigate = useNavigate();
    const [userData, setUserdata] = useState({ signedIn: false, user: null });
    const { setAuthData } = useContext(AuthContext);
    useEffect(() => {
        // eslint-disable-next-line
        setAuthData(userData);
        // eslint-disable-next-line
    }, [userData.signedIn]);
    function getAuthCookieExpiration() {
        const date = new Date();
        date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000));  // 7 days
        return date;
    }
    function setAsLogged(user: any, token: any) {
        persistUser(user);
        persistToken(token);
        const cookie = new Cookies();
        cookie.set('is_auth', true, { path: '/', expires: getAuthCookieExpiration(), sameSite: 'lax', httpOnly: false });
        setUserdata({ signedIn: true, user: user });

    }
    function setLogout() {
        httpApi.post('/api/logout').then((response) => {
            if (response.data.success == true) {
                cookie.remove('is_auth', { path: '/', expires: getAuthCookieExpiration(), sameSite: 'lax', httpOnly: false });
                setUserdata({ signedIn: false, user: null });
                deleteToken();
                deleteUser();
                navigate('/auth/login');
            }
        })
        const cookie = new Cookies();

    }
    function loginUserOnStartup() {
        const cookie = new Cookies();
        if (cookie.get('is_auth')) {
            httpApi.get('/api/me').then(response => {
                if (response.data.success == true) {
                    setUserdata({ signedIn: true, user: response.data.data });
                }
            }).catch(error => {
                setUserdata({ signedIn: false, user: null });
                setLogout();
            });
        } else {
            setUserdata({ signedIn: false, user: null });
            navigate('/auth/login');
        }
    }

    return {
        userData,
        setAsLogged,
        setLogout,
        loginUserOnStartup
    }
};