import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useAuth } from '@app/hooks/useAuth';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/LoginPage';
import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
import NewPasswordPage from '@app/pages/NewPasswordPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import ProfileLayout from '@app/components/profile/ProfileLayout';
import UserLayout from '@app/components/user/UserLayout';
import TeamLayout from '@app/components/team/TeamLayout';
import ProductLayout from '@app/components/product/ProductLayout';
import ReportLayout from '@app/components/report/ReportLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';
import NftDashboardPage from '@app/pages/DashboardPages/NftDashboardPage';

const DataTablesPage = React.lazy(() => import('@app/pages/DataTablesPage'));
const ServerErrorPage = React.lazy(() => import('@app/pages/ServerErrorPage'));
const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));
const RegisterPage = React.lazy(() => import('@app/pages/RegisterPage'));
const PersonalInfoPage = React.lazy(() => import('@app/pages/PersonalInfoPage'));
const Logout = React.lazy(() => import('./Logout'));
//ProductPage//
const IndexProductPage = React.lazy(() => import('@app/pages/ProductPages/IndexProduct'));
const CreateProductPage = React.lazy(() => import('@app/pages/ProductPages/CreateProduct'));
const EditProductPage = React.lazy(() => import('@app/pages/ProductPages/EditProduct'));
const ShowProductPage = React.lazy(() => import('@app/pages/ProductPages/ShowProduct'));
//UserPage//
const IndexUserPage = React.lazy(() => import('@app/pages/UserPages/IndexUser'));
const CreateUserPage = React.lazy(() => import('@app/pages/UserPages/CreateUser'));
const EditUserPage = React.lazy(() => import('@app/pages/UserPages/EditUser'));
const ShowUserPage = React.lazy(() => import('@app/pages/UserPages/ShowUser'));
//TeamPage//
const IndexTeamPage = React.lazy(() => import('@app/pages/TeamPages/IndexTeam'));
const CreateTeamPage = React.lazy(() => import('@app/pages/TeamPages/CreateTeam'));
const ShowTeamPage = React.lazy(() => import('@app/pages/TeamPages/ShowTeam'));
const EditTeamPage = React.lazy(() => import('@app/pages/TeamPages/EditTeam'));

export const NFT_DASHBOARD_PATH = '/';

const LoginUser = withLoading(LoginPage);
const NftDashboard = withLoading(NftDashboardPage);
const Register = withLoading(RegisterPage);

const DataTables = withLoading(DataTablesPage);

const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);

// Product
const IndexProduct = withLoading(IndexProductPage);
const CreateProduct = withLoading(CreateProductPage);
const ShowProduct = withLoading(ShowProductPage);
const EditProduct = withLoading(EditProductPage);

// User
const IndexUser = withLoading(IndexUserPage);
const CreateUser = withLoading(CreateUserPage);
const ShowUser = withLoading(ShowUserPage);
const EditUser = withLoading(EditUserPage);

// Team
const IndexTeam = withLoading(IndexTeamPage);
const CreateTeam = withLoading(CreateTeamPage);
const ShowTeam = withLoading(ShowTeamPage);
const EditTeam = withLoading(EditTeamPage);

// Profile
const PersonalInfo = withLoading(PersonalInfoPage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );
  return (
    <BrowserRouter>
      <Routes>
        <Route path={NFT_DASHBOARD_PATH} element={protectedLayout}>
          <Route index element={<NftDashboard />} />
          <Route path="data-tables" element={<DataTables />} />
          <Route path="server-error" element={<ServerError />} />
          <Route path="404" element={<Error404 />} />
          <Route path="profile" element={<ProfileLayout />}>
            <Route path="personal-info" element={<PersonalInfo />} />
          </Route>

          <Route path="/product/index" element={<IndexProduct />} />
          <Route path="/product/create" element={<CreateProduct />} />
          <Route path="/product/show/:id" element={<ShowProduct />} />
          <Route path="/product/edit/:id" element={<EditProduct />} />

          <Route path="/user/index" element={<IndexUser />} />
          <Route path="/user/create" element={<CreateUser />} />
          <Route path="/user/show/:id" element={<ShowUser />} />
          <Route path="/user/edit/:id" element={<EditUser />} />
          
          
          <Route path="/team/index" element={<IndexTeam />} />
          <Route path="/team/create" element={<CreateTeam />} />
          <Route path="/team/show/:id" element={<ShowTeam/>} />
          <Route path="/team/edit/:id" element={<EditTeam/>} />

        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="sign-up" element={<RegisterPage />} />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="new-password" element={<NewPasswordPage />} />
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
      </Routes>
    </BrowserRouter>
  );
};
