import React from 'react';
import {
  CompassOutlined,
  FormOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { ReactComponent as NftIcon } from '@app/assets/icons/nft-icon.svg';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'common.nft-dashboard',
    key: 'nft-dashboard',
    // TODO use path variable
    url: '/',
    icon: <NftIcon />,
  },
  {
    title: 'สินค้าทั้งหมด',
    key: 'maps',
    icon: <CompassOutlined />,
    children: [
      {
        title: 'รายการสินค้าทั้งหมด',
        key: 'product-all',
        url: '/product/index',
      },
      {
        title: 'เพิ่มสินค้าใหม่',
        key: 'product-created',
        url: '/product/create',
      },
    ],
  },
  {
    title: 'รายงาน',
    key: 'forms',
    icon: <FormOutlined />,
    children: [
      {
        title: 'รายงาน',
        key: 'advanced-forms',
        url: '/report',
      },
    ],
  },
  {
    title: 'ผู้ใช้งาน/ทีมงาน',
    key: 'auth',
    icon: <UserOutlined />,
    children: [
      {
        title: 'ทีมงาน',
        key: 'login',
        url: '/team/index',
      },
      {
        title: 'ผู้ใช้งาน',
        key: 'singUp',
        url: '/user/index',
      },
      {
        title: 'สิทธิ์ผู้ใช้งาน',
        key: 'lock',
        url: '/user/index',
      },
    ],
  },
];
