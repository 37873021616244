import React, { useState } from 'react';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import thTH from 'antd/lib/locale/th_TH';
import GlobalStyle from './styles/GlobalStyle';
import 'typeface-montserrat';
import 'typeface-lato';
import { AppRouter } from './components/router/AppRouter';
import { useLanguage } from './hooks/useLanguage';
import { usePWA } from './hooks/usePWA';
import { useAppSelector } from './hooks/reduxHooks';
import { themeObject } from './styles/themes/themeVariables';

const App: React.FC = () => {
  const { language } = useLanguage();
  const theme = useAppSelector((state) => state.theme.theme);

  usePWA();

  return (
    <>
      <meta name="theme-color" content={themeObject[theme].primary} />
      <meta httpEquiv="cache-control" content="no-cache" />
        <meta httpEquiv="expires" content="0" />
          <meta httpEquiv="pragma" content="no-cache" />
            <GlobalStyle />
            <ConfigProvider locale={language === 'th' ? thTH : enUS}>
              <AppRouter />
            </ConfigProvider>
          </>
          );
};

          export default App;
