import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { notificationController } from '@app/controllers/notificationController';
import * as S from './LoginForm.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { useAuth } from "../../../hooks/useAuth";
import AuthContext from "../../../context/authContext";
import { httpApi } from '@app/api/http.api';

interface LoginFormData {
  email: string;
  password: string;
  remember: boolean;
}

export const initValues: LoginFormData = {
  email: '',
  password: '',
  remember: false
};

export const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [ isLoading, setLoading] = useState(false);
  const { setAsLogged } = useAuth();
  const { authData } = useContext(AuthContext);

  const [error, setError] = useState(null);

  const handleSubmit = (values: LoginFormData) => {
    setLoading(true);
    setError(null);
    const payload = {
      email: values.email,
      password: values.password,
      remember: values.remember
    };

    if(values.remember){
      payload.remember = true;
    }

    httpApi.post('/api/login', payload).then(response => {
      setAsLogged(response.data.data.user, response.data.data.token);
      navigate('/');
    }).catch(error => {
      console.log(error);
      notificationController.error({ message: error.message });
      setLoading(false);
    });
  };

  useEffect(() => {
    if (!authData.signedIn) {
      navigate('/auth/login');
    }
  }, []);

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
        <Auth.FormTitle>{t('common.login')}</Auth.FormTitle>
        <S.LoginDescription>{t('login.loginInfo')}</S.LoginDescription>
        {error && (
							<div
								className="flex p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800"
								role="alert">
								<svg
									aria-hidden="true"
									className="flex-shrink-0 inline w-5 h-5 mr-3"
									fill="currentColor"
									viewBox="0 0 20 20"
									xmlns="http://www.w3.org/2000/svg">
									<path
										fillRule="evenodd"
										d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
										clipRule="evenodd"></path>
								</svg>
								<span className="sr-only">Info</span>
								<div>{error}</div>
							</div>
						)}
        <Auth.FormItem
          name="email"
          label="อีเมล์แอดเดรส"
          rules={[
            { required: true, message: 'กรุณากรอกอีเมล์แอดเดรส' },
            {
              type: 'email',
              message: 'รูปแบบอีเมล์ไม่ถูกต้อง',
            },
          ]}
        >
          <Auth.FormInput placeholder="กรุณากรอกอีเมล์แอดเดรส" />
        </Auth.FormItem>
        <Auth.FormItem
          label='รหัสผ่าน'
          name="password"
          rules={[{ required: true, message: "กรุณากรอกรหัสผ่าน" }]}
        >
          <Auth.FormInputPassword placeholder="กรุณากรอกรหัสผ่าน" />
        </Auth.FormItem>
        <Auth.ActionsWrapper>
          <BaseForm.Item name="rememberMe" valuePropName="checked" noStyle>
            <Auth.FormCheckbox>
              <S.RememberMeText>{t('login.rememberMe')}</S.RememberMeText>
            </Auth.FormCheckbox>
          </BaseForm.Item>
          <Link to="/auth/forgot-password">
            <S.ForgotPasswordText>{t('common.forgotPass')}</S.ForgotPasswordText>
          </Link>
        </Auth.ActionsWrapper>
        <BaseForm.Item noStyle>
          <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('common.login')}
          </Auth.SubmitButton>
        </BaseForm.Item>
        <Auth.FooterWrapper>
          <Auth.Text>
            <Link to="/auth/sign-up">
              <Auth.LinkText>ลงทะเบียนใช้งาน</Auth.LinkText>
            </Link>
          </Auth.Text>
        </Auth.FooterWrapper>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
